import React, { useEffect, useMemo, useState } from "react";
import { useTable, useFilters, usePagination } from "react-table";
import { getDenunce } from '../../helpers/elba_helper';
import DatePicker from 'react-datepicker';
import ListPerizie from "pages/Perizie/ListPerizie";
import Modal from "./Modal";
import 'react-datepicker/dist/react-datepicker.css'; 
import './ListDenunce.css';

// Funzione per definire un semplice input per il filtro di testo
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value.trim() || undefined) // Aggiorna il filtro
            }}
            placeholder={`Cerca...`}
            className="form-control"
        />
    )
}

const ListaDenunce = () => {
    const [denunceList, setDenunceList] = useState([]);
    const [certificatiList,setCertificatiList] = useState([])
    const [consorzioList,setConsorzioList] = useState([])
    const [agenziaList,setAgenziaList] = useState([])
    const [ordini, setOrdini] = useState()
    const [comuneList,setComuneList] = useState([])   
    const [provinciaList,setProvinciaList] = useState([])
    const [specieList,setSpecieList] =useState([])
    const [varietaList,setVarietaList] =useState([])
    const [produzioneAssicurataList,setProduzioneAssicurataList] =useState([])
    const [capitaleAssicuratoList,setCapitaleAssicuratoList] =useState([])
    const [tipoDiEventoList, setTipoDiEventoList] = useState([])

    const [nomeAssicuratoList,setNomeAssicuratoList] =useState([])
    const [messaggioModal,setMessaggioModal] = useState()

    const [activedFilter,setActiveFilter] = useState()


    const [openModal,setOpenModal] = useState(false)

    const [certificatoAgenziaConsorzioToModal,setCertificatoAgenziaConsorzioToModale] = useState({})

    const givMeDenunce = async () => {
        const resp = await getDenunce();
        resp.denunce.map((ele) => ele.archiviata = ele.archiviata ? 'archiviata' : 'aperta' )
        setDenunceList([...resp.denunce]);
        populateSelect(resp.denunce)

    };

    const populateSelect =  (denunce)=> {


        let [listCertificato,listAgenzia,listConsorzio,listComuni,listProvince,listSpecie,listVarieta,listProduzioneAssicurata,listCapitaleAssicurato,listDataEvento,listNomeAssicurato,listTipoDiEvento] = [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            []

        ]
        let listOrdini =[]

        denunce.forEach(element => {
            console.log(element)
            listCertificato.push(element.Certificato)
            listAgenzia.push(element.Agenzia)
            listConsorzio.push(element.Consorzio)
            listComuni.push(element.Comune)
            listProvince.push(element.Provincia)
            listSpecie.push(element.Specie)
            listVarieta.push(element.Varieta)
            listProduzioneAssicurata.push(element.ProduzioneAssicurata)
            listCapitaleAssicurato.push(element.CapitaleAssicurato)
            listDataEvento.push(element.DataEvento)
            listNomeAssicurato.push(element.NomeAssicurato)
            listTipoDiEvento.push(element.TipoDiEvento)
            listOrdini.push(`${element.Certificato}|${element.Agenzia}|${element.Consorzio}|${element.Comune}|${element.Provincia}|${element.Specie}|${element.Varieta}|${element.ProduzioneAssicurata}|${element.CapitaleAssicurato}|${element.DataEvento}|${element.NomeAssicurato}`)

        });
        const getUniqueValues = (array) => (
            [...new Set(array)]
        )
        listCertificato = getUniqueValues(listCertificato)
        listAgenzia = getUniqueValues(listAgenzia).sort()
        listConsorzio = getUniqueValues(listConsorzio).sort()
        listComuni= getUniqueValues(listComuni).sort()
        listProvince= getUniqueValues(listProvince).sort()
        listSpecie= getUniqueValues(listSpecie).sort()
        listVarieta= getUniqueValues(listVarieta).sort()
        listProduzioneAssicurata= getUniqueValues(listProduzioneAssicurata).sort()
        listCapitaleAssicurato= getUniqueValues(listCapitaleAssicurato).sort()
        listDataEvento= getUniqueValues(listDataEvento)
        listNomeAssicurato= getUniqueValues(listNomeAssicurato).sort()
        listTipoDiEvento = getUniqueValues(listTipoDiEvento).sort()
        listOrdini = getUniqueValues(listOrdini)
        
        setCertificatiList([...listCertificato])
        setConsorzioList([...listConsorzio])
        setAgenziaList([...listAgenzia])
        setComuneList([...listComuni])
        setProvinciaList([...listProvince])
        setSpecieList([...listSpecie])
        setVarietaList([...listVarieta])
        setProduzioneAssicurataList([...listProduzioneAssicurata])
        setCapitaleAssicuratoList([...listCapitaleAssicurato])
        setNomeAssicuratoList([...listNomeAssicurato])
        setTipoDiEventoList([...listTipoDiEvento])
        setOrdini([...listOrdini])
        
    }

    useEffect(() => {
        givMeDenunce();
    }, []);


    const returnDataFiltered  = (property,value) => {

        if(!property || !value ) {
            if (!value) {
                givMeDenunce()
            }
            return;
        }


        //let listCertificato = []
        let listAgenzia = []
        let listConsorzio = []
        let propertyToCheck = ['Certificato','Agenzia','Consorzio','Comune','Provincia','Varieta','ProduzioneAssicurata','CapitaleAssicurato','DataEvento','NomeAssicurato']
    
       /* propertyToCheck = propertyToCheck.filter((e)=>{
            e != property
        })*/
        let index = propertyToCheck.indexOf(property)
        let result = ordini.filter(x => x.split('|')[index] == value);


        console.log(result)
        result.forEach(element => {
            const [certificato,agenzia,consorzio] = element.split('|')
            listAgenzia.push(agenzia)
            listConsorzio.push(consorzio)
        });
        const getUniqueValues = (array) => (
            [...new Set(array)]
        )

        listAgenzia = getUniqueValues(listAgenzia).sort()
        listConsorzio = getUniqueValues(listConsorzio).sort()
        //setCertificatiList([...listCertificato])
        setConsorzioList([...listConsorzio])
        setAgenziaList([...listAgenzia])
    }


    const filterResult = (obj) => {
        console.log(obj)
        const {property, value} = obj;
        returnDataFiltered(property,value);
    }

    // Definire le colonne per react-table
    const columns =  useMemo(
        () => [
            {
                Header: "Partita",
                accessor: "Partita",
                Filter: false,
                
            },
            {
                Header: "Certificato",
                accessor: "Certificato",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                    className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e) =>{
                                        console.log(e)
                                        setFilter(e.target.value || undefined) 
                                        filterResult({ property: 'Certificato', value:e.target.value})
                                    }} 
                                >
                                    <option value="">Tutti</option>
                                    {certificatiList.length && certificatiList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Agenzia",
                accessor: "Agenzia",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                    className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'Agenzia',value:e.target.value}) 
                                    }}
                                >
                                    <option value="">Tutti</option>
                                    {agenziaList.length && agenziaList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header:"Consorzio",
                accessor: "Consorzio",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                     className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'Consorzio',value:e.target.value})
                                    }} // Aggiorna il filtro
                                >
                                    <option value="">Tutti</option>
                                    {consorzioList.length && consorzioList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Comune",
                accessor: "Comune",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                     className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'Comune',value:e.target.value})
                                    }} // Aggiorna il filtro
                                >
                                    <option value="">Tutti</option>
                                    {comuneList.length && comuneList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Provincia",
                accessor: "Provincia",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                     className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'Provincia',value:e.target.value})
                                    }} // Aggiorna il filtro
                                >
                                    <option value="">Tutti</option>
                                    {provinciaList.length && provinciaList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Specie",
                accessor: "Specie",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                     className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'Specie',value:e.target.value})
                                    }} // Aggiorna il filtro
                                >
                                    <option value="">Tutti</option>
                                    {specieList.length && specieList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Varieta",
                accessor: "Varieta",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                     className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'Varieta',value:e.target.value})
                                    }} // Aggiorna il filtro
                                >
                                    <option value="">Tutti</option>
                                    {varietaList.length && varietaList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Produzione Assicurata",
                accessor: "ProduzioneAssicurata",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                     className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'ProduzioneAssicurata',value:e.target.value})
                                    }} // Aggiorna il filtro
                                >
                                    <option value="">Tutti</option>
                                    {produzioneAssicurataList.length && produzioneAssicurataList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Capitale Assicurato",
                accessor: "CapitaleAssicurato",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                     className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'CapitaleAssicurato',value:e.target.value})
                                    }} // Aggiorna il filtro
                                >
                                    <option value="">Tutti</option>
                                    {capitaleAssicuratoList.length && capitaleAssicuratoList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Tipo Di Evento",
                accessor: "TipoDiEvento",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                     className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'TipoDiEvento',value:e.target.value})
                                    }} // Aggiorna il filtro
                                >
                                    <option value="">Tutti</option>
                                    {tipoDiEventoList.length && tipoDiEventoList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Data Evento",
                accessor: "DataEvento",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <div
                         className="standard-select"
                        >
                            <DatePicker
                                onChange={(e)=> {
                                    const date = new Date(e);
                                    const year = date.getFullYear();
                                    const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi sono da 0 a 11, quindi aggiungi 1
                                    const day = String(date.getDate()).padStart(2, '0');
                                    setFilter(`${year}-${month}-${day}` || undefined)
                                }}
                                dateFormat="yyyy-MM-dd"
                                placeholderText={filterValue || 'seleziona  data'}
                            />
                        </div>
                    )
                },
            },
            {
                Header: "Nome Assicurato",
                accessor: "NomeAssicurato",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <select
                                     className="standard-select"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        setFilter(e.target.value || undefined)
                                        filterResult({property:'NomeAssicurato',value:e.target.value})
                                    }} // Aggiorna il filtro
                                >
                                    <option value="">Tutti</option>
                                    {nomeAssicuratoList.length && nomeAssicuratoList.map((el, index) => (
                                        <option key={index} value={el}>{el}</option>
                                    ))}
                                </select>
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Archiviata",
                accessor:"archiviata",
                Filter: ({
                    column: { filterValue, preFilteredRows, setFilter },
                }) => {
                    return (
                        <>
                            <div className="select">
                                <input type="checkbox"
                                    className="standard-select ck-in-denunce"
                                    value={filterValue || ""} // Setta il valore selezionato nel filtro
                                    onChange={(e)=>{ 
                                        console.log(e)
                                        setFilter(e.target.checked ? 'archiviata' : 'aperta')
                                        //filterResult({property:'TipoDiEvento',value:e.target.value})
                                    }} // Aggiorna il filtro
                                />
                                <span className="focus"></span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: "Azioni",
                Filter:false
            }

        ],
        [certificatiList,agenziaList,consorzioList,comuneList,provinciaList,specieList,varietaList,produzioneAssicurataList,capitaleAssicuratoList,nomeAssicuratoList]
    );

    // Creare la tabella usando react-table con filtri e paginazione
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: denunceList,
            initialState: { pageIndex: 0, pageSize: 10 }, // Imposta la pagina iniziale
        },
        useFilters, // Usa filtri
        usePagination // Usa paginazione
    );



    const openModalFunction = (obj) => {
        setCertificatoAgenziaConsorzioToModale(obj)
        setOpenModal(true)
      };
    
      const closeModal = () => {
        setOpenModal(false);
        setMessaggioModal()
      };


      const emptyPeriziaWarning = () => {
        setMessaggioModal('Spiacente, nessuna perizia trovata collegata a questo ordine')

      }
    
    return (
        <div>

            {!openModal && <h3>Lista Denunce</h3>}
            {openModal && <>
                <h3>Lista Perizie</h3>
                <p>stai visualizzando le perizie per il certificato <b>{certificatoAgenziaConsorzioToModal.certificato}</b></p> 
            </>}
                <Modal isOpen={openModal} onClose={closeModal}>
                    {
                    !messaggioModal ? 
                    
                    
                        <ListPerizie  
                            certificato={certificatoAgenziaConsorzioToModal.certificato} 
                            agenzia={certificatoAgenziaConsorzioToModal.agenzia} 
                            consorzio={certificatoAgenziaConsorzioToModal.consorzio} 
                            emptyPeriziaWarning={emptyPeriziaWarning}
                            nrecord={10000}
                        /> : 
                        
                        <div>
                            {messaggioModal}
                        </div>
                    }
                </Modal>
               { !openModal && <div>
            <table {...getTableProps()} className="table table-striped">
                <thead>
                    {headerGroups.map((headerGroup,ind) => (
                        <tr key={ind} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,indexCol) => (
                                <th key={indexCol} {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row,indRow) => {
                        prepareRow(row);
                        return (
                            <tr key={indRow} {...row.getRowProps()}>
                                {row.cells.map((cell,indCell) => (
                                    <td key={indCell} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                                    <td key={13} 
                                        onClick ={(e) =>
                                            openModalFunction(
                                                {
                                                    certificato: row.cells[1].render('Cell'),
                                                    agenzia:row.cells[2].render('Cell'),
                                                    consorzio:row.cells[3].render('Cell')
                                                }
                                            )
                                        }
                                        > 

                                        <div className="margin-btn-attivity">
                                            Apri perizia
                                        </div>
                                    </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <button className="btn btn-primary m-1" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button className="btn btn-primary m-1"  onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button className="btn btn-primary m-1"  onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button className="btn btn-primary m-1"  onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: '100px', marginRight: '10px' }}
                    />
                </span>
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            </div> }
        </div>
    );
};

export default ListaDenunce;
